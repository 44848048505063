import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

const initialCart = {
  all_items: { data: [] },
  subsidized_tip: 0,
  subsidized_hst: 0,
  tip: 0,
  hst: 0,
  hst_rate: 0.13,
  subtotal: 0,
  subsidized_subtotal: 0,
  grand_total: 0,
  payable_amount: 0,
  is_weekly: true,
  available_credit: 0,
  isSubsidy: false,
};

class PlaceOrderStore {
  users = [];
  mealMenu = null;
  optionCategories = null;
  tip = { label: '0%', value: 0 };
  cart = initialCart;

  constructor() {
    makeObservable(this, {
      users: observable,
      mealMenu: observable,
      optionCategories: observable,
      tip: observable,
      cart: observable,
    });
  }

  async getUsers(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.users()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          let officeEmployees = [];
          data?.data?.map(user => {
            return user.attributes.location_employees
              .filter(emp => !emp?.attributes.soft_deleted)
              ?.map(employee => {
                let label = `${user?.attributes.first_name + ' ' + user?.attributes.last_name} # ${
                  employee?.attributes.account_name
                } ${user?.attributes?.email}`;

                officeEmployees = [
                  ...officeEmployees,
                  {
                    label,
                    value: employee?.id,
                    accountName: employee?.attributes.account_name,
                    email: user?.attributes?.email,
                    organization: employee.attributes.organization,
                    user_id: user.id,
                  },
                ];
              });
          });

          this.users = officeEmployees;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  clearOptionCategories() {
    this.optionCategories = null;
  }

  async getOptionCategories(itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menuOptions()}/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.optionCategories = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addItemToCart(empId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.companyEmployees()}/${empId}/cart_items?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCart(empId, showTip, params, hideError) {
    let paramsString = qs.stringify(params);
    return client()
      .get(
        `${api.companyEmployees()}/${empId}/cart_items?${paramsString}&tip_percentage=${
          this.tip.value / 100
        }`
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          this.cart = data;
          return data;
        }),
        action('fetchError', error => {
          if (!hideError) alertHandler(error.response);
          return error;
        })
      );
  }

  setTip = (tip, empId, showTip, params) => {
    this.tip = tip;
    this.getCart(empId, showTip, params);
  };

  resetCart = tip => {
    this.cart = initialCart;
  };

  async removeItemFromCart(empId, itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.companyEmployees()}/${empId}/cart_items/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async clearCart(empId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.companyEmployees()}/${empId}/cart_items?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default PlaceOrderStore;
