import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Tooltip({ tooltipText, id }) {
  return (
    <div className="flex px-1 cursor-pointer">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color={'#000'}
        size="sm"
        data-tip={tooltipText}
        data-for={id}
      />
      <ReactTooltip
        id={id}
        className="tooltip w-auto"
        type="success"
        effect="solid"
        multiline={true}
        textColor="#fff"
        place="right"
        backgroundColor="#000"
      />
    </div>
  );
}
export default Tooltip;
